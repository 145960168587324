/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 11/03/2022
 * Time: 09:50
 */


import {formsDB} from "src/services/indexedDB/formsDB.js"
import {api} from 'boot/axios.js'
import {persistMobiledata} from './persistMobiledata.js'
import store from 'src/store/index.js'
import Router from 'src/router/index.js'

export const logoutService = {


  async logout() {
    if ( !logoutService.checkLoggedin() ) return

    await persistMobiledata.prepareSync()

    await formsDB.delete()

    await api.get( '/api/logout' )

    localStorage.setItem( 'loggedIn', false )
    localStorage.removeItem( 'activeClient' )
    localStorage.removeItem( 'rehabilitation' )
    localStorage.removeItem( 'user' )
// console.log( Router );
//     Router.push( {name: 'start'} )

    location.reload()
  },
  checkLoggedin() {
    let loggedIn = localStorage.getItem( ('loggedIn') )
    if ( !loggedIn ) return false

    loggedIn = JSON.parse( loggedIn )
    return loggedIn
  }


}




