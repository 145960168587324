/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 16.10.23
 * Time: 13:20
 */
import {formsDB} from "src/services/indexedDB/formsDB";

export const conversion204 = {

  fWordsMilestonesDomain: 1,
  icfMilestonesDomain   : 7,

  async doConversion() {
    let rehabilitations = await formsDB.rehabilitations.toArray()

    rehabilitations.forEach( async rehabilitation => {
      let rehappdomainID = conversion204.icfMilestonesDomain
      if( rehabilitation.domains.find( domain => domain.rehappdomain_id == conversion204.fWordsMilestonesDomain)){
        rehappdomainID = conversion204.fWordsMilestonesDomain
      }

      let milestoneDomain =
            rehabilitation.domains.find( d => d.type === 'MILESTONES' )

      if ( !milestoneDomain ) return

      let bodyFunction =
            rehabilitation.domains.find( d => d.rehappdomain_id == rehappdomainID )

      milestoneDomain.challenges.forEach( c => {
        c.rehappdomain_id = rehappdomainID
        bodyFunction.challenges.push( c )
      } )
      milestoneDomain.abilities.forEach( a => {
        a.rehappdomain_id = rehappdomainID
        bodyFunction.abilities.push( a )
      } )
      let milestoneIndex = rehabilitation.domains.findIndex( d => d.type === "MILESTONES" )

      if ( milestoneIndex !== -1 ) rehabilitation.domains.splice( milestoneIndex, 1 )

      await formsDB.rehabilitations.update( rehabilitation.id, rehabilitation )
    } )
  }


}

