/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 23/05/2022
 * Time: 08:33
 */

import EasySpeech from 'easy-speech'

export const speechService = {
  initialized: false,
  speechLanguage : 'EN',



  async init() {
//     if( ! speechService.checkSpeak() ) return
    if ( speechService.initialized ) return

    speechService.initialized = true

    await EasySpeech.init( {maxTimeout: 5000, interval: 250} )
  },
  async getVoices() {
    return speechService.init()
      .then( async () => {
        return await EasySpeech.voices()
      } )
  },
  async getLanguageVoices( languageCode ) {
    let voices = await speechService.getVoices()

    let allowedVoices = []
    voices.forEach( v => {
      if ( v.lang.substring( 0, 2 ).toUpperCase() == languageCode ) {
        allowedVoices.push( v )
      }
    } )
    return allowedVoices
  },
  async speak( text, chosenVoice = false, force = false ) {
    if ( speechService.checkSpeak() === false && force === false ) return;

    return speechService.init()
      .then( async () => {
        speechService.getSelectedVoice()
          .then( voice => {
            speechService.speakIt( text, voice )
          } )
      } )
  },
  async speakIt( text, chosenVoice ) {
    if( ! speechService.checkSpeak() ) return
    speechService.stopSpeak()
    text = speechService.stripText( text )
    if ( text === '' ) return
    let voices = EasySpeech.voices();
    let languageCode = 'EN'
    let settings = localStorage.getItem( 'settings' )
    if( settings){
      settings = JSON.parse(settings)
      let languageCode = settings.languageCode
    }
    let voice = voices.find( v => v.lang.substring( 0, 2 ).toUpperCase() == languageCode )

    await EasySpeech.speak( {
      text  : text,
      voice : voice,// optional, will use a default or fallback
      pitch : 1.0,
      rate  : .95,
      volume: 1,
      // there are more events, see the API for supported events
      boundary: event => console.debug('word boundary reached', event.charIndex),
      error: e => console.log(e)
    } ).catch( e => {
      return e.error
    } )
  },
  async getSelectedVoice() {
    let voices = await speechService.getVoices()

    let voice = localStorage.getItem( 'voice' )

    if ( voice ) return voices.find( v => v.voiceURI == voice )

    let languageCode = helpers.getSettingsValue( 'languageCode' )
    if ( languageCode ) return voices.find( v => v.lang.substring( 0, 2 ).toUpperCase() == languageCode )

    return voices[10]
  },
  checkSpeak() {
    let appData = localStorage.getItem( 'APP-DATA' )

    return appData ? JSON.parse( appData ).text2speech : true
  },
  stopSpeak() {
    if( speechService.initialized === false) return
    try{
      EasySpeech.cancel()
    }catch(e){
      console.log( e )
    }
  },
  stripText( text ) {
    text = text.replace( /(<([^>]+)>)/gi, "" );
    text = text.replace( '&nbsp;', '' )
    text = text.replace( /&nbsp;/g, '' )

    return text
  }

}
