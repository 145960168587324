/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 09/01/2022
 * Time: 09:03
 */

import {formsDB} from './formsDB'
import {date} from 'quasar'
import {v4 as uuidv4} from "uuid"

export const goalsDB = {}

goalsDB.allGoals = async function ( fillinformUuid ) {
  let goals = await formsDB.descriptionswhere( {} ).toArray()

  return goals
}

goalsDB.getGoaldescriptions = async function ( fillinformUuid, cycledomainID = false, type = false ) {
  let where = {}
  where.fillinform_uuid = fillinformUuid
  if ( cycledomainID ) where.cycledomain_id = cycledomainID
  if ( type ) where.type = type

  let descriptions = await formsDB.descriptions.where( where ).toArray()

  return descriptions
}

goalsDB.getActions = async function ( descriptionUUID ) {
  return await formsDB.actions.where( {description_uuid: descriptionUUID} ).toArray()
}

goalsDB.addDescription = async function ( cycledomainID, fillinFormUUID, type ) {
  let description = {
    cycledomain_id  : cycledomainID,
    fillinform_uuid : fillinFormUUID,
    type            : type,
    uuid            : uuidv4(),
    description     : '',
    goal            : '',
    why             : '',
    cycle_rhythm    : '',
    cycle_start     : '',
    cycle_end       : '',
    goal_status     : 'X',
    goal_achievement: 1,
    goal_evaluation : '',
    archived        : 'F',
    archive_date    : '',
    goal_editted    : 'F',
    active          : 'R', // 'F', 'T', 'R'
    saved           : 'F'
  }

  await formsDB.descriptions.add( description )
  formsDB.setStaleData()

  return description
}

goalsDB.addAction = async function ( descriptionUUID ) {
  let action = {
    uuid            : uuidv4(),
    description_uuid: descriptionUUID,
    action          : '',
    ready           : 'F',
    saved           : 'F'
  }
  formsDB.setStaleData()
  return await formsDB.actions.add( action )
}

goalsDB.saveCycleDescription = async function ( uuid, description ) {
  description.saved = 'F'
  await formsDB.descriptions.where( {uuid: uuid} ).modify( description )
  formsDB.setStaleData()
}

goalsDB.saveAction = async function ( action ) {
  action.saved = 'F'
  formsDB.setStaleData()
  return await formsDB.actions.where( {uuid: action.uuid} ).modify( action )
}

goalsDB.removeItem = async function ( descriptionUUID ) {
  formsDB.setStaleData()
  await formsDB.removed_items.add({uuid:descriptionUUID, table:'descriptions'})
  await formsDB.actions.where({description_uuid: descriptionUUID}).delete()
  return await formsDB.descriptions.where( {uuid: descriptionUUID} ).delete()
}

goalsDB.removeAction = async function ( actionUUID ) {
  formsDB.setStaleData()
  await formsDB.removed_items.add({uuid:actionUUID, table:'actions'})
  return await formsDB.actions.where( {uuid: actionUUID} ).delete()
}

goalsDB.getGoalsAndActions = async function ( fillinformUuid ) {
  // Query
  const goals = await formsDB.descriptions
    .where( {fillinform_uuid: fillinformUuid} )
    .toArray();

  await Promise.all( goals.map( async goal => {
    [goal.actions] = await Promise.all([
      formsDB.actions.where( {description_uuid: goal.uuid} ).toArray()
    ]);
  } ) );

  return goals
}

