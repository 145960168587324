/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 05/09/2022
 * Time: 11:11
 */


export const clientColors = [
  {
    background: '#e63946',
    color     : '#fff'
  },
  {
    background: '#fca311',
    color     : '#000'
  },
  {
    background: '#ff006e',
    color     : '#fff'
  },
  {
    background: '#003049',
    color     : '#fff'
  },
  {
    background: '#fb8500',
    color     : '#fff'
  },
  {
    background: '#023e8a',
    color     : '#fff'
  },
  {
    background: '#48cae4',
    color     : '#000'
  },
  {
    background: '#f15bb5',
    color     : '#000'
  },
  {
    background: '#c1121f',
    color     : '#fff'
  },
  {
    background: '#9a031e',
    color     : '#fff'
  },
  {
    background: '#8ac926',
    color     : '#000'
  },
  {
    background: '#7b2cbf',
    color     : '#fff'
  },
  {
    background: '#4f772d',
    color     : '#fff'
  },
  {
    background: '#30638e',
    color     : '#fff'
  },
  {
    background: '#ff8fa3',
    color     : '#000'
  },
  {
    background: '#80ffdb',
    color     : '#000'
  },
  {
    background: '#540d6e',
    color     : '#fff'
  },
]
