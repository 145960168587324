/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 19.10.23
 * Time: 09:18
 */

import Dexie from 'dexie'
import {v4 as uuidv4} from "uuid"
import {db} from "src/services/indexedDB/db";

export const appUsageDB = new Dexie( 'app_usage' );

appUsageDB.version( 4 ).stores( {
  app_install: '++id, uuid',
  app_usage  : '++id, app_id, synced',
  error_log : '++id, app_id',
} );

// Check if app install exists
