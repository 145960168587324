import {api} from '../../boot/axios'
import {hiappDB} from 'src/services/indexedDB/hiappDB'
import {formClientsDB} from 'src/services/indexedDB/formClientsDB'
import {helpers} from 'src/services/helpers'

export default ({
  namespaced: true,
  state     : {
    subjectIndex: null,
    hiappClient  : null,
    hiappSubjects: null,
    activeHiappForm: null
  },
  mutations : {
    setHiappClientform( state, hiappClientform ) {
      state.activeHiappForm = hiappClientform
      state.hiappSubjects = hiappClientform.hiapp_subjects.sort( ( a, b ) => {
        if ( a.order < b.order ) return -1;

        return 1;
      } )
    },
    setHiSubjectChosen( state, payload ) {
      for ( let hiappSubject of state.hiappSubjects ) {
        for ( let hiIssue of hiappSubject.hi_issues ) {
          if ( hiIssue.id === payload.hiIssueID ) {
            hiIssue.chosen = payload.status
            let hiappForm = helpers.V2JS( state.activeHiappForm )
            hiappDB.updateHiappform( hiappForm  )
          }
        }
      }
    },
    setHiCauseChosen( state, payload ) {
      for ( let hiappSubject of state.hiappSubjects ) {
        for ( let hiIssue of hiappSubject.hi_issues ) {
          for ( let hiCause of hiIssue.hi_causes ) {
            if ( hiCause.id === payload.hiCauseID ) {
              hiCause.chosen = payload.status
              let hiappForm = helpers.V2JS( state.activeHiappForm )
              hiappDB.updateHiappform( hiappForm  )
            }
          }
        }
      }
    },
    setHiSolutionChosen( state, payload ) {
      for ( let hiappSubject of state.hiappSubjects ) {
        for ( let hiIssue of hiappSubject.hi_issues ) {
          for ( let hiCause of hiIssue.hi_causes ) {
            for( let hiSolution of hiCause.hi_solutions){
              if ( hiSolution.id === payload.hiSolutionID ) {
                hiSolution.chosen = payload.status
                hiSolution.prescriptionTxt = payload.prescriptionTxt
                let hiappForm = helpers.V2JS( state.activeHiappForm )
                hiappDB.updateHiappform( hiappForm  )
              }
            }
          }
        }
      }
    },
    setSubjectIndex(state, subjectIndex){
      state.subjectIndex = subjectIndex
    }
  },
  actions   : {
    setHiappClientform( context, hiappClientform ) {
      context.commit( 'setHiappClientform', hiappClientform )
    },
    setHiSubjectChosen( context, payload ) {
      context.commit( 'setHiSubjectChosen', payload )
    },
    setHiCauseChosen( context, payload ) {
      context.commit( 'setHiCauseChosen', payload )
    },
    setHiSolutionChosen( context, payload ) {
      context.commit( 'setHiSolutionChosen', payload )
    },
    setSubjectIndex(context, subjectIndex){
      context.commit('setSubjectIndex', subjectIndex)
    },
    createHiAppPdf(context, formData){
      return api.post('/api/hiapp/createHiAppPdf', formData)
    }
  },
  getters   : {}
})

