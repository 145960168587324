/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 24.10.23
 * Time: 09:26
 */


import {v4 as uuidv4} from "uuid";
import {api} from "boot/axios";
import {appUsageDB} from "src/services/logging/appLogging";

export const appRegistration = {

  async getAppInstall() {
    let appInstall = await appUsageDB.app_install.toArray()
if ( !appInstall || appInstall.length == 0 ) {
      return await this.createAppInstall()
    }

    return appInstall[0]
  },
  async createAppInstall() {
    const platform = navigator.platform
    const useragent = navigator.userAgent
    const appID = uuidv4()

    const appInstall = {
      uuid         : appID,
      platform     : platform,
      useragent    : useragent,
      app_specifics: {},
      synced       : false
    }

    await appUsageDB.table( 'app_install' ).add( appInstall )

    await this.registerAppInstall( appInstall )

    localStorage.setItem('APP-ID', appInstall.uuid)

    return appInstall
  },

  async setAppSpecifics() {
    let appInstall = await this.getAppInstall()

    const localStorageVars = ['languageCode', 'regionID', 'applicationID', 'countryID']

    localStorageVars.forEach( lVar => {
      if ( localStorage.getItem( lVar ) )
        appInstall.app_specifics[lVar] = localStorage.getItem( lVar )
    } )

    appInstall.synced = false

    appUsageDB.app_install.where( {uuid: appInstall.uuid} ).modify( appInstall )

    this.registerAppInstall(appInstall)
  },
//create or update serverside app install
  async registerAppInstall( appInstall ) {
    console.log( 'REGISTER APP' );
    if ( !navigator.onLine ) return
    let formData = new FormData()
    formData.append( 'formData', JSON.stringify( appInstall ) )

    const result = await api.post( '/api/logging/registerAppInstall', formData )
    if ( result.status == 200 ) {
      await appUsageDB.table( 'app_install' ).where( {uuid: appInstall.uuid} ).modify( {synced: true} )
    }
  }
}


