/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 14/06/2022
 * Time: 13:50
 */


import {db} from './db'
import {formsDB} from './formsDB'
import {v4 as uuidv4} from "uuid"
import {persistMobiledata} from "src/services/sync/persistMobiledata";

export const milestonesDB = {}

milestonesDB.timeout = 0
milestonesDB.currentDB = null

milestonesDB.getDomains = async function () {
  return await db.milestones.toArray()
}

milestonesDB.getCategories = async function () {
  let milestones = await db.milestones.toArray()

  return milestones[0].categories
}

milestonesDB.getMilestones = async function () {
  let milestones = await db.milestones.toArray()

  return milestones[0]
}

milestonesDB.getClientMilestoneForm = async function ( clientUUID, myChild = false ) {
  milestonesDB.setCurrentDB( myChild )

  return await milestonesDB.currentDB.client_milestones
    .where( {client_uuid: clientUUID} )
    .first()
}

milestonesDB.addMilestonesForm = async function ( milestonesForm, myChild = false ) {
  milestonesDB.setCurrentDB( myChild )
  let mf = JSON.stringify( milestonesForm )
  milestonesForm = JSON.parse( mf )
  await milestonesDB.currentDB.client_milestones.add( milestonesForm )

  await persistMobiledata.prepareSync()
}

milestonesDB.saveMilestoneForm = async function ( milestoneform, myChild = false ) {
  milestonesDB.setCurrentDB( myChild )

  milestoneform.synced = 0
  let mf = JSON.stringify( milestoneform )
  milestoneform = JSON.parse( mf )

  milestonesDB.currentDB.client_milestones
    .where( {uuid: milestoneform.uuid} ).modify( milestoneform )

  await persistMobiledata.prepareSync()
}

milestonesDB.getMilestoneChildren = async function () {
  return await db.my_children.toArray()
}

milestonesDB.saveChild = async function ( myChild ) {
  let child = await db.my_children.where( {uuid: myChild.uuid} ).first()

  myChild = JSON.parse( JSON.stringify( myChild ) );
  if ( !child ) {
    return await db.my_children.add( myChild )
  } else {
    return await db.my_children.where( {uuid: myChild.uuid} ).modify( myChild )
  }
}

milestonesDB.removeMyChild =async function (myChild){
  await db.client_milestones.where({client_uuid : myChild.uuid}).delete()

  return await db.my_children.where({uuid:myChild.uuid}).delete()
}

milestonesDB.setCurrentDB = function ( myChild ) {
  milestonesDB.currentDB = myChild ? db : formsDB
}


