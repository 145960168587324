/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 29/03/2022
 * Time: 16:56
 */

//import {store} from 'src/store/index.js'
import {db} from 'src/services/indexedDB/db.js'

export const helpers = {}



helpers.getTranslationFromArray = function ( identifier, translations ) {
  let translation = translations.find( o => o.identifier == identifier )

  return translation ? translation.translation : 'NB'
}


helpers.getTranslations = async function ( identifiers ) {
  let translations = await db.translations.where( 'identifier' ).anyOf( identifiers ).toArray()

  return translations
}


helpers.getTranslation = async function ( identifier ) {
  let translation = await db.translations.where( {'identifier': identifier} ).first()

  return translation ? translation.translation : ''
}

helpers.getAge = function ( birthdate ) {
  const validDate = new Date(birthdate);
  if( isNaN(validDate) ) return false

  let birthDate = new Date(birthdate)
  let diff = new Date() - birthDate
  let age = new Date(diff)

  return Math.abs( age.getUTCFullYear() - 1970 )
}

helpers.V2JS = function ( vueObject ) {
  let jsonString = JSON.stringify( vueObject )
  let jsObject = JSON.parse( jsonString )

  return jsObject
}

helpers.getAgeInMonths = function ( birthdate ) {
    let months = 0;
    let d1 = new Date( birthdate )
    let d2 = new Date()

    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

helpers.getSettingsValue = function (settingsName){
  let settings = JSON.parse(localStorage.getItem('settings'))

  if(!settings) return false

  return settings[settingsName]
}
