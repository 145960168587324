import {api} from '../../boot/axios'
import {milestonesDB} from 'src/services/indexedDB/milestonesDB'
import {formClientsDB} from 'src/services/indexedDB/formClientsDB'
import {persistMobiledata} from "src/services/sync/persistMobiledata";

export default ({
  namespaced: true,
  state     : {
    milestonesForm      : [],
    milestonescategoryID: null,
    milestonedomains    : [],
    milestonecategories : [],
    milestoneClient     : null,
    clientBirthdate     : null,
    selectedMyChild     : false
  },
  mutations : {
    setMilestonesForm( state, milestonesForm ) {
      state.milestonesForm = milestonesForm
    },
    setMilestonedomains( state, milestonedomains ) {
      state.milestonedomains = milestonedomains
    },
    setMilestonecategories( state, milestonecategories ) {
      state.milestonecategories = milestonecategories
    },
    setMilestoneClient( state, client ) {
      state.milestoneClient = client
    },
    setClientBirthdate( state, birthdate ) {
      state.clientBirthdate = birthdate
    },
    setMilestonescategoryID( state, milestonescategoryID ) {
      state.milestonescategoryID = milestonescategoryID
    },
    changeIndicatorStatus( state, payload ) {
      let milestonesForm = state.milestonesForm
      let category = milestonesForm.categories.find( c => c.id == payload.categoryID )
      let indicator = category.indicators.find( i => i.id == payload.indicatorID )
      indicator.status = payload.newStatus
      milestonesDB.saveMilestoneForm( state.milestonesForm, payload.myChild )
    },
    setSelectedMyChild( state, myChild ) {
      state.selectedMyChild = myChild
    }
  },
  actions   : {
    setMilestonesForm( context, milestonesForm ) {
      context.commit( 'setMilestonesForm', milestonesForm )
    },
    getMilestonedomains( context ) {
      milestonesDB.getDomains()
        .then( result => {
          context.commit( 'setMilestonedomains', result )
        } )
    },
    getMilestonecategories( context ) {
      milestonesDB.getCategories()
        .then( result => {
          context.commit( 'setMilestonecategories', result )
        } )
    },
    setMilestoneClient( context, clientUUID ) {
      return formClientsDB.getClientByUUID( clientUUID )
        .then( client => {
          context.commit( 'setMilestoneClient', client )
          context.dispatch( 'getClientBirthdate', clientUUID )
        } )
    },
    getClientBirthdate( context, clientUUID ) {
      return formClientsDB.getClientBirthdate( clientUUID )
        .then( birthdate => {
          context.commit( 'setClientBirthdate', birthdate )
        } )
    },
    changeIndicatorStatus( context, payload ) {
      context.commit( 'changeIndicatorStatus', payload )
    },
    setMilestonescategoryID( context, milestonescategoryID ) {
      context.commit( 'setMilestonescategoryID', milestonescategoryID )
    },
    setActivechild( context, child ) {
      context.commit( 'setActivechild', child )
    },
    setSelectedMyChild( context, myChild ) {
      context.commit('setSelectedMyChild', myChild)
      context.dispatch('setMyChildBirthdate', myChild)
    },
    setMyChildBirthdate(context, myChild){
      return context.commit( 'setClientBirthdate', myChild.birthdate )
    },
  },
  getters   : {
    milestoneClientAge( state ) {
      let months = 0;
      let d1 = new Date( state.clientBirthdate )
      let d2 = new Date()

      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
  }
})

