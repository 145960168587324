/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 12/02/2022
 * Time: 15:41
 */
import {db} from '../indexedDB/db.js'
import {formsDB} from "src/services/indexedDB/formsDB.js";

export const articleDB = {}

articleDB.getDefaultArticle = async function(){
  let article = db.articles.where({default: 1}).first()

  return article;
}

articleDB.getArticle = async function ( articleID ) {
  let article = await db.articles.where( {id: articleID} ).first()

  return articleDB.ExtractArticle( article )
}

articleDB.getArtcileByUUID = async function(uuid){
  let article = await db.articles.where( {uuid: uuid} ).first()

  return article
}

articleDB.getChildpage = async function ( articleID, articleUUID ) {
  let article = await db.articles.where( {id: articleID} ).first()
  let foundChild
  for ( let i = 0; i < article.childpages.length; i++ ) {
    foundChild = articleDB.findChildpage( article.childpages[i], articleUUID )
    if ( foundChild ) return articleDB.ExtractArticle( foundChild )
  }
}

articleDB.findChildpage = function ( childpage, articleUUID ) {
  let node
  if ( childpage.id === articleUUID ) return childpage

  childpage.childpages.some(
    child => node = articleDB.findChildpage( child, articleUUID ) )

  return node;
}

articleDB.ExtractArticle = function ( article ) {

  let extractedArticle = {
    article   : article.article,
    childpages: []
  }

  article.childpages.forEach( cp => {
    extractedArticle.childpages.push( {
      id   : cp.uuid,
      title: cp.translation
    } )
  } )

  return extractedArticle
}




