/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 24/02/2022
 * Time: 08:29
 */

import {formsDB} from './formsDB'
import {v4 as uuidv4} from "uuid"

export const formClientsDB = {}

formClientsDB.getClientByUUID = async function( clientUUID){
  return formsDB.clients.where( {uuid: clientUUID} ).first()
}
formClientsDB.archiveClient = async function( clientUUID){
  let client = await formsDB.clients.where({uuid:clientUUID}).first()

  client.archived = ! client.archived
  formsDB.clients.where({uuid:clientUUID}).modify({
    archive: client.archived, persist: true})
  formsDB.setStaleData()
}
formClientsDB.removeClient = async function( clientUUID){
  await formsDB.removed_items.add({uuid:clientUUID, table:'clients'})
  await formsDB.clients.where({uuid:clientUUID}).delete()
  formsDB.setStaleData()
}
formClientsDB.saveClientStatus = async function ( uuid, clientStatus ) {
  formsDB.clients.where( {uuid: uuid} ).modify( {status: clientStatus, saved: 'F'} )
  formsDB.setStaleData()
}
formClientsDB.getClientBirthdate = async function( uuid){
  let client = await formsDB.clients.where( {uuid: uuid}).first()

  let formField = client.clientFormFields.find( c => c.birthdate == 1 )

  return formField.answer
}
formClientsDB.getClientInfo = async function ( clientUUID, form ) {

}
