/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 25/02/2022
 * Time: 07:52
 */

import Dexie from 'dexie'
import {db} from '../indexedDB/db.js'
import {formsDB} from "src/services/indexedDB/formsDB.js"
import {v4 as uuidv4} from "uuid"
import {api} from 'boot/axios.js'
import {date} from 'quasar'
import {Notify} from 'quasar'
import {helpers} from "src/services/helpers"

export const syncrecords = new Dexie( 'syncrecords' );
syncrecords.version( 3 ).stores( {
  records: 'uuid',
} );

export const persistMobiledata = {

  user              : false,
  staleData         : 'F',
  records           : [],
  result            : null,
  callback          : null,
  formData          : null,
  busy              : false,
  timeout           : null,
  timeoutCounter    : 0,
  lastStorageMessage: false,

  async prepareSync() {
    await persistMobiledata.checkStorage();

    persistMobiledata.setUser()

    if ( !persistMobiledata.user ) return

    await persistMobiledata.insertSyncRecords()

    if ( !navigator.onLine ) return

    await persistMobiledata.syncDB()

    await persistMobiledata.sendRemovedrecords()
  },
  async checkStorage() {
    if ( ! navigator.storage || ! navigator.storage.estimate) return;
    navigator.storage.estimate().then( async ( estimate ) => {
      const usedStorage = estimate.usage;
      const availableStorage = estimate.quota;
      const threshold = 0.85; // Set a threshold (e.g., 90% used)
      const critical = 0.9;
      const percentageUsed = usedStorage / availableStorage;
      let msg = false
      let timeout = 30000

//       if( percentageUsed < threshold ) return

      let lastMessage = localStorage.getItem( 'lastMessage' ) || 0

      console.log( 'lastMessage', lastMessage )

      const currentTime = Date.now();
      const timeDifference = currentTime - lastMessage
      console.log( 'timedifference', timeDifference );
      if( timeDifference < 7200000 ) return;

      console.log( 'BYPASSED' );

      localStorage.setItem( 'lastMessage', Date.now() )

      if ( percentageUsed < critical ) {
        msg = await helpers.getTranslation( 'CRITICALSTORAGE' )
        timeout = 10000;
      } else if ( percentageUsed > threshold ) {
        msg = await helpers.getTranslation( 'TRESHOLD' )
      }

      if ( !msg ) return;

      Notify.create( {
        message: msg,
        timeout: timeout,
        color  : 'negative',
      } )
    } );
  },
  setUser() {
    persistMobiledata.user = localStorage.getItem( 'user' )

    if ( !persistMobiledata.user || persistMobiledata.user == 'undefined' ) return

    persistMobiledata.user = JSON.parse( persistMobiledata.user )
  },
  async insertSyncRecords() {
    const tableNames = await formsDB.tables.map( ( table ) => table.name );
    for ( let tableName of tableNames ) {
      if ( tableName == 'version' || tableName == 'removed_items' ) continue

      let records = await formsDB.table( tableName )
        .where( 'synced' )
        .equals( 0 )
        .toArray()

      //Modify the records which are persisted in the sync database
      await formsDB.table( tableName )
        .where( 'synced' )
        .equals( 0 )
        .modify( {synced: 1} )

      if ( !records || records.length == 0 ) continue

      for ( const record of records ) {
        const payload = {
          user_id: persistMobiledata.user.id,
          uuid   : record.uuid,
          table  : tableName,
          record : record
        }
        await syncrecords.records.put( payload )
      }
    }

  },
  async syncForLogout() {
    await persistMobiledata.syncDB()
  },
  async syncDB() {
    if ( persistMobiledata.checkBusy() ) return
    persistMobiledata.setBusy()

    let records = await syncrecords.records.toArray()

    if ( !records || records.length == 0 ) return

    await persistMobiledata.sendToServer( records )
  },
  async sendToServer( records ) {
//     return persistMobiledata.busy = false
    try {
      let formData = new FormData()
      formData.append( 'payload', JSON.stringify( records ) )
      let response = await api.post( '/api/saveTables', formData )

      persistMobiledata.disableBusy()
      if ( response.status === 200 ) {
        const keys = records.map( records => records.uuid );

        await syncrecords.records.bulkDelete( keys );
      }
    } catch (error) {
      persistMobiledata.disableBusy()
    }
  },
  async sendRemovedrecords() {
    let removed = await formsDB.removed_items.toArray()

    if ( !removed || removed.length == 0 ) return

    let formData = new FormData()
    formData.append( 'removed_items', JSON.stringify( removed ) )

    await api.post( '/api/saveRemovedrecords', formData )

    await formsDB.removed_items.clear()
  },
  setBusy() {
    persistMobiledata.timeout = null
    persistMobiledata.busy = true
    persistMobiledata.timeout = setTimeout( () => {
      persistMobiledata.busy = false
    }, 5000 )
  },
  checkBusy() {
    persistMobiledata.timeoutCounter++
    if ( persistMobiledata.timeoutCounter > 5 ) {
      persistMobiledata.timeoutCounter = 0
      return false
    }

    return persistMobiledata.busy
  },
  disableBusy() {
    persistMobiledata.timeout = null
    persistMobiledata.busy = false
    persistMobiledata.timeoutCounter = 0
  },
  setStaleData() {
    persistMobiledata.staleData = localStorage.getItem( 'stale_data' ) ?? 'F'
  }
}


