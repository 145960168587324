import {api} from '../../boot/axios'


export default ({
  namespaced: true,
  state     : {
    pages            : [],
    defaultpages     : [],
    activePageID     : null,
    mainArticleID    : null,
    selectedPageID    : null
  },
  mutations : {
    setPages( state, payload ) {
      state.pages = payload
    },
    setDefaultpages( state, payload ) {
      state.defaultpages = payload
    },
    setActivepageID( state, payload ) {
      state.activePageID = payload
    },
    setMainArticleID( state, payload ) {
      state.mainArticleID = payload
    },
    setSelectedPageID( state, pageID ) {
      state.selectedPageID = pageID
    }

  },
  actions   : {
    getPages( context ) {

    },
    getDefaultpages( context ) {
    },
    setActivePageID( context, pageID ) {
      context.commit( 'setActivepageID', pageID )
    },
    setMainArticleID( context, payload ) {
      context.commit( 'setMainArticleID', payload )
    },
    setSelectedPageID( context, pageID ) {
      context.commit( 'setSelectedPageID', pageID )
    }
  },
  getters   : {
    getPage( state ) {
      return state.pages.find( page => {
        return page.id == state.activePageID
      } )
    },
    defaultStartpage( state ) {
      return state.defaultpages.find( defaultpage => {
        return defaultpage.component == 'Start'
      } )
    }
  }
})

