/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 24.10.23
 * Time: 09:34
 */
import {appUsageDB} from "src/services/logging/appLogging";
import {api} from "boot/axios";

export const errorLogging = {

  async logError( e ) {
    if( e.fileName){
      if ( e.fileName.includes( 'errorLogging' ) ) return
      if ( e.fileName.includes( 'axios' ) ) return
    }
    let error = {
      name      : e.name,
      message   : e.message,
      fileName  : e.fileName,
      lineNUmber: e.lineNumber
    }
    let record = {
      app_id: localStorage.getItem( 'APP-ID' ),
      date  : new Date(),
      error : JSON.stringify( error )
    }

    await appUsageDB.error_log.add( record )

    await this.registerErrors()
  },
  async registerErrors() {
    if ( !navigator.onLine ) return

    let errors = await appUsageDB.error_log.toArray()

    errors.forEach( async error => {
      let formData = new FormData()
      formData.append( 'formData', JSON.stringify( error ) )

        let result = await api.post( '/api/logging/logError', formData )

        if ( result.status == 200 )
          await appUsageDB.error_log.delete( error.id )
    } )
  }


}
