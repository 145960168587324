/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 28/12/2021
 * Time: 10:18
 */

import {api} from 'src/boot/axios'
import {formsDB} from 'src/services/indexedDB/formsDB'
import {goalsDB} from "src/services/indexedDB/goalsDB"
import {rehabilitationDB} from "src/services/indexedDB/rehabilitationDB"
import {clientColors} from 'src/services/appointments/clientColors'
import {helpers} from 'src/services/helpers'
import {persistMobiledata} from 'src/services/sync/persistMobiledata'
import {date} from "quasar"
import {v4 as uuidv4} from "uuid"


export default ({
  namespaced: true,
  state     : {
    activeClient  : null,
    rehabilitation: null,
    cycleform     : {},
    clientColors  : clientColors
  },
  mutations : {
    setRehabilitation( state, rehabilitation ) {
      state.rehabilitation = rehabilitation
    },
    setActiveClient( state, client ) {
      state.rehabilitation = null
      state.activeClient = client
    },
    saveFormFieldAnswer( state, payload ) {
      state.activeClient.synced = 0
      let formField =
            state.activeClient.clientFormFields.find(
              field => field.uuid == payload.formField.uuid )
      formField.answer = payload.localAnswer

      if ( formField.clientField == 1 )
        state.activeClient.clientname = payload.localAnswer

      let isValid = true
      state.activeClient.clientFormFields.forEach( field => {
        if ( field.mandatory == 1 && field.answer.length < 1 ) isValid = false
      } )
      state.activeClient.valid = isValid

      if ( payload.formField.birthdate == 1 ) {
        let age = helpers.getAge( payload.localAnswer )
        if ( age ) state.activeClient.age = age
      }

      formsDB.saveActiveClient( state.activeClient )
      persistMobiledata.prepareSync()
    },
    saveClient( state, payload ) {
      state.activeClient.synced = 0
      console.log( payload );
      state.activeClient[payload.field] = payload.value
      console.log( state.activeClient );
      formsDB.saveActiveClient( state.activeClient )
    },
    async saveKeyboardInput( state, payload ) {
      state.activeClient.clientFormFields.forEach( field => {
        if( field.component == 'Checkbox'){
          field.categories.forEach( category => {
            if( category.id == payload.id){
              category.text_input = payload.text_input
            }
          })
        }
      })
      formsDB.saveActiveClient( state.activeClient )
      persistMobiledata.prepareSync()
    },
    async addGoal( state, payload ) {
      let domain = state.rehabilitation.domains.find(
        domain => domain.rehappdomain_id == payload.rehappDomainID )

      if ( !domain ) return false

      state.rehabilitation.synced = 0

      domain[payload.type].push( payload.goal)

      //await rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    addAction( state, payload ) {
      let domain =
            state.rehabilitation.domains.find(
              domain => domain.rehappdomain_id == payload.rehappDomainID )

      let challangeAbility =
            domain[payload.type].find( ability => ability.uuid == payload.uuid )

      challangeAbility.actions.push( {
        uuid       : uuidv4(),
        description: '',
        ready      : false
      } )
    },
    async addDiaryItem(state, diaryItem) {
      state.rehabilitation.diary_items.push( diaryItem )

      await rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    saveGoal( state, payload ) {
      let domain =
            state.rehabilitation.domains.find(
              domain => domain.rehappdomain_id == payload.rehappDomainID )

      let challangeAbility =
            domain[payload.type].find( ability => ability.uuid == payload.uuid )

      challangeAbility[payload.field] = payload.value

      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    saveAction( state, payload ) {
      let domain =
            state.rehabilitation.domains.find(
              domain => domain.rehappdomain_id == payload.rehappDomainID )

      let challangeAbility =
            domain[payload.type].find( ability => ability.uuid == payload.uuid )

      let action = challangeAbility.actions.find( action => action.uuid == payload.actionUUID )

      action[payload.field] = payload.value

      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    saveLadderOfLive( state, payload ) {
      state.rehabilitation.ladder_of_live.forEach( lol => {
        if ( lol.uuid == payload.uuid ) {
          lol.answer = payload.answer
        }
      } )
      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    saveDiaryItem(state, payload){
      state.rehabilitation.diary_items.forEach( item => {
        if ( item.uuid == payload.uuid ) {
          item[payload.field] = payload.value
        }
      })
      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    saveDiarySatisfaction( state, payload ) {
      state.rehabilitation.diary_items.forEach( item => {
        if ( item.uuid == payload.diaryItem.uuid ) {
          payload.diaryItem.satisfactions.forEach( s => {
            if(s.uuid == payload.satisfaction.uuid){
              s.satisfaction = payload.score
            }
          })
        }
      })
      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    removeGoal( state, payload ) {

            state.rehabilitation.domains.forEach( domain =>{
              domain[payload.type].forEach((ca, caIndex)=>{
                if( ca.uuid == payload.uuid ) {
                  domain[payload.type].splice( caIndex, 1 )
                }
              })
            })

      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    deleteAction( state, payload ) {
      let domain =
            state.rehabilitation.domains.find(
              domain => domain.rehappdomain_id == payload.rehappDomainID )

      let challangeAbility =
            domain[payload.type].find( ability => ability.uuid == payload.uuid )

      const index = challangeAbility.actions.findIndex( item => item.uuid === payload.actionUUID )

      if ( index !== -1 ) challangeAbility.actions.splice( index, 1 );

      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    deleteDiaryItem(state, payload){
      const index = state.rehabilitation.diary_items.findIndex( item => item.uuid === payload.uuid )

      if ( index !== -1 ) state.rehabilitation.diary_items.splice( index, 1 );

      rehabilitationDB.saveRehabilitation( state.rehabilitation )
    },
    async addUuidToDairyItems(state){
      //Versions before 2.06 didn't have an uuid for diary items
      if( ! state.rehabilitation ) return
      console.log( state.rehabilitation );
      console.log( 'CONVERT DIARYITEMS' );
      state.rehabilitation.diary_items.forEach( diaryItem => {
        if( ! diaryItem.uuid) diaryItem.uuid = uuidv4()
      } )

      await rehabilitationDB.saveRehabilitation(  state.rehabilitation )
    }
  },
  actions   : {
    setRehabilitation( context, rehabilitation ) {
      context.commit( 'setRehabilitation', rehabilitation )
      localStorage.setItem( 'rehabilitation', JSON.stringify( rehabilitation ) )
    },
    setActiveClient( context, client ) {
      context.commit( 'setActiveClient', client )
      localStorage.setItem( 'activeClient', JSON.stringify( client ) )
    },
    saveFormFieldAnswer( context, payload ) {
      context.commit( 'saveFormFieldAnswer', payload )
    },
    saveClient( context, payload ) {
      context.commit( 'saveClient', payload )
    },
    saveKeyboardInput( context, payload ) {
      context.commit( 'saveKeyboardInput', payload )
    },
    async addGoal( context, payload ) {
      let domain = context.state.rehabilitation.domains.find(
        domain => domain.rehappdomain_id == payload.rehappDomainID )

      if ( !domain ) return false

      const goal = {
        uuid           : uuidv4(),
        type           : payload.type,
        rehappdomain_id: payload.rehappDomainID,
        activationdate : null,
        completiondate : null,
        description    : '',
        goal           : '',
        why            : '',
        evaluation     : '',
        actions        : [],
        satisfaction   : 0,
        achieved       : false,
        active         : false, // if the goal has green indicator ( activly working on )
        finished       : false, // finished anbd no longer working on
      }

      payload.goal = goal

      context.commit( 'addGoal', payload );

      await rehabilitationDB.saveRehabilitation( context.state.rehabilitation )

      return goal
    },
    addAction( context, payload ) {
      context.commit( 'addAction', payload )
    },
    addUuidToDiaryItem( context, payload ){
      context.commit( 'addUuidToDiaryItem', payload)
    },
    saveGoal( context, payload ) {
      return context.commit( 'saveGoal', payload )
    },
    addDiaryItem( context, payload ) {
      return context.commit( 'addDiaryItem', payload )
    },
    saveAction( state, payload ) {
      state.commit( 'saveAction', payload )
    },
    saveLadderOfLive( context, payload ) {
      context.commit( 'saveLadderOfLive', payload )
    },
    saveDiaryItem(context, payload){
      context.commit( 'saveDiaryItem', payload)
    },
    saveDiarySatisfaction(context, payload){
      context.commit('saveDiarySatisfaction', payload)
    },
    removeGoal( context, payload ) {
      context.commit( 'removeGoal', payload )
    },
    deleteAction( context, payload ) {
      context.commit( 'deleteAction', payload )
    },
    deleteDiaryItem(context, payload){
      context.commit( 'deleteDiaryItem', payload )
    },
    addUuidToDairyItemsyItems(context ) {
      return context.commit( 'addUuidToDairyItems' );
    }
  },
  getters   : {
    getActiveClient(state){
      return state.activeClient
    },
    //getDiaryItem()
  }
})


