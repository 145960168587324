/**
 * Created by Pensare Software.
 * User: Theo Weijmer
 * Date: 11/12/2022
 * Time: 09:47
 */


import {db} from './db'
import {formsDB} from './formsDB'
import {v4 as uuidv4} from "uuid"
import {helpers} from "src/services/helpers";

export const hiappDB = {}

hiappDB.timeout = 0

hiappDB.getHiappClientforms = async function () {
  let hiappClientforms = await db.hiapp_client_forms.toArray()

  return hiappClientforms
}

hiappDB.getHiappClientform = async function ( uuid ) {
  let hiappClientform = await db.hiapp_client_forms.where( {uuid: UUID} ).first()

  if ( hiappClientform ) return hiappClientform
}

// Get the base form for Hi-app
hiappDB.getHiappForm = async function () {
  let hiappForm = await db.hiapp_form.toArray()

  return hiappForm
}

// create a new client specific hi-app form
hiappDB.createUpdateHiappForm = async function ( clientReference ) {
  let hiappForm = await hiappDB.getHiappForm()
  let hiappClientform = {
    uuid            : uuidv4(),
    client_reference: clientReference,
    hiapp_subjects  : hiappForm
  }

  await db.hiapp_client_forms.add( hiappClientform )
}

hiappDB.updateHiappform = async function ( hiappClientform ) {
  hiappClientform = helpers.V2JS( hiappClientform )
  db.hiapp_client_forms.put( hiappClientform )
//   db.hiapp_client_forms.update( {hiapp_subjects: hiappSubjects} )
}

hiappDB.removeHiappForm = async function ( uuid) {
  await db.hiapp_client_forms.where( {uuid: uuid} ).delete()
}




